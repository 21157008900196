<template>
  <div :style="{ minHeight: $vuetify.breakpoint.xs ? 'auto' : '539px' }" class="d-flex flex-grow-1 align-center pb-12">
    <v-card :width="$vuetify.breakpoint.xs ? '100%' : '360'" height="full-height" flat>
      <v-card-text>
        <h4 class="font-weight-black mb-2 text-center text-sm-left" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Reset Password</h4>

        <v-sheet class="mx-auto" :max-width="$vuetify.breakpoint.xs ? '250' : '100%'">
          <p class="body-1 mb-5 text-center text-sm-left">
            Provide your existing email address.
          </p>
        </v-sheet>

        <v-alert v-if="status.error" type="error" border="left" class="mb-5" text>
          {{ status.error }}
        </v-alert>
      
        <v-alert v-if="status.success" type="success" border="left" text>
          {{ status.success }}
        </v-alert>

        <div v-if="!status.success">
          <v-form ref="loginForm" @submit.prevent="validateSignin()">
            <v-text-field
              v-model="data.email"
              :rules="[rules.required, rules.email]"
              label="Email Address"
              height="48"
              outlined
              dense
            ></v-text-field>
          </v-form>

          <v-btn
            @click="validateSignin()"
            :loading="status.resetting"
            color="accent gradient"
            class="rounded-lg"
            block
            >Send</v-btn
          >

          <div class="mt-5">
            <router-link
              :to="{ name: 'Login' }"
              class="body-1 black--text caption"
            >
              Back to login page
            </router-link>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "@/rules";

export default {
  data() {
    return {
      rules,
    }
  },

  computed: {
    ...mapState({
      data: (state) => state.user.data,
      status: (state) => state.user.status,
    }),
  },

  watch: {
    user: function (value) {
      if (value) {
        this.$router.push({ name: 'Jobs' })
      }
    },
  },

  methods: {
    ...mapActions("user", ["resetPassword"]),

    validateSignin() {
      if (this.$refs.loginForm.validate()) {
        this.resetPassword()
      }
    },
  },

  mounted() {
    this.$store.commit('user/setError', false)
  }
}
</script>

<style lang="scss">
.v-main {
  &.withBackground {
    .v-main__wrap {
      background: url("/images/bg1.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}
</style>
